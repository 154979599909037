/* esline-disable */
import { isSP } from '@oro/static-site-modules'
export { isSP }

export function isPC () {
  return !isSP()
}

/* eslint-disable */
const GLOBAL_NAV_HEIGHT = 105

window.handleAccordion = handleAccordion
window.onMouseoverAndOut = onMouseoverAndOut

document.addEventListener('DOMContentLoaded', function () {
  toggleHeaderMenu()
  // floatingHandler()
  const contact = document.querySelector('.header_contact')
  const facebook = document.querySelector('.header_facebook')
  const global = document.querySelector('.header_global')

  onMouseoverAndOut(
    facebook,
    facebook.querySelector('img'),
    '/images/share',
    `ico-facebook`,
    'svg'
  )

  onMouseoverAndOut(
    contact,
    contact.querySelector('img'),
    '/images/share',
    `ico-contact`,
    'svg'
  )

  onMouseoverAndOut(
    global,
    global.querySelector('img'),
    '/images/share',
    `ico-global`,
    'svg'
  )

  const headerAnchorContainers = document.querySelectorAll('.header_anchor_container')
  const headerNavWrappers = document.querySelectorAll('.header_more')
  const isInheaderAnchorContainers = new Array(headerNavWrappers.length)

  for(let i = 0; i < headerNavWrappers.length; i++) {
    headerNavWrappers[i].addEventListener('mouseover', function() {
      headerAnchorContainers[i].style.display = 'block'
    })

    headerNavWrappers[i].addEventListener('mouseout', function() {
      if(!isInheaderAnchorContainers[i])
        headerAnchorContainers[i].style.display = 'none'
    })

    headerAnchorContainers[i].addEventListener('mouseover', function() {
      isInheaderAnchorContainers[i] = true
    })

    headerAnchorContainers[i].addEventListener('mouseout', function() {
      isInheaderAnchorContainers[i] = false
    })
  }
})

// window.addEventListener('load', function() {
//   smoothScroll()
// })

function onMouseoverAndOut(ele, image, path, baseName, extend = 'png') {
  const originSrc = image.src

  ele.addEventListener('mouseover', function() {
    image.src = `${path}/${baseName}_on.${extend}`
  })

  ele.addEventListener('mouseout', function() {
    image.src = originSrc
  })
}

function toggleHeaderMenu() {
  const headMenu = document.querySelector('.header_menu')
  const headNav = document.querySelector('.header_nav')
  const headModal = document.querySelector('.header_modal')
  const headMenuImg = document.querySelector('.header_menu > img')
  let isOpen = false

  handleAccordion('header_more', 'header_anchor_wrapper')

  headMenu.addEventListener('click', function() {
    headNav.style.display = isOpen ? 'none' : 'block';
    headModal.style.display = isOpen ? 'none' : 'block';
    headMenuImg.src = isOpen ? '/images/share/ico-menu.png' : '/images/share/ico-menu_close.png',
    isOpen = !isOpen
  })
}

function toggleAccordion(eleButton, eleContents, options = {}) {
  return function() {
    eleContents.forEach(function(eleContent) {
      if (!eleContent.style.maxHeight || eleContent.style.maxHeight === '0px') {
        eleContent.style.maxHeight = '50000px'
      } else {
        eleContent.style.maxHeight = 0
        options.isInner && eleButton.scrollIntoView(true)
      }
    })

    if(options.triggleClass) {
      const triggleElement = options.triggleElement || eleButton
      triggleElement.classList.toggle(options.triggleClass)
    }
  }
}

function handleAccordion(selector, triggleClass) {
  const accordions = document.querySelectorAll(`.${selector}`)

  accordions.forEach(
    function(accordion) {
      if(!accordion || typeof accordion !== 'object') return

      const accordionButtons = accordion.querySelectorAll(`.${selector}_button`)
      const accordionContents = accordion.querySelectorAll(`.${selector}_content`)

      accordionButtons.forEach(function(accordionButton, i) {
        accordionButton.addEventListener(
          'click',
          toggleAccordion(
            accordionButton,
            accordionContents,
            { isInner: i !== 0, triggleClass, triggleElement: accordion }
          )
        )
      })
    }
  )
}

// function smoothScroll() {
//   const internalAnchors = document.querySelectorAll('a[href^="#"]')
//   const isSP = window.matchMedia('(max-width: 767px)').matches

//   function doSmoothScroll(targetElementId) {
//     let targetElement

//     if(targetElementId === '#')
//       targetElement = document.querySelector('body')
//     else
//       targetElement = document.querySelector(targetElementId) || document.querySelector('body')

//     if(isSP) {
//       targetElement.scrollIntoView({ behavior: 'smooth' })
//     } else {
//       const rect = targetElement.getBoundingClientRect()
//       window.scroll({
//         top: window.scrollY + rect.y - GLOBAL_NAV_HEIGHT,
//         behavior: 'smooth'
//       })
//     }
//   }
  
//   window.location.hash && doSmoothScroll(window.location.hash)

//   for(let i = 0; i < internalAnchors.length; i++) {
//     const targetElementId = internalAnchors[i].href.slice(internalAnchors[i].href.lastIndexOf('#'))

//     internalAnchors[i].addEventListener('click', function(e) {
//       e.preventDefault()
//       doSmoothScroll(targetElementId)
//     })
//   }
// }

// function floatingHandler(appearAt) {
//   const float = document.querySelector('.floating')
//   if(!float) return

//   document.addEventListener('scroll', function() {
//     if(window.scrollY < (appearAt || 300)
//       || window.scrollY + window.innerHeight > document.body.scrollHeight) {
//       float.style.opacity = 0
//     } else {
//       float.style.opacity = 1
//     }
//   })
// }

$(function(){
  $(window).scroll(function(){
    var scroll_top = $(document).scrollTop();
    if(scroll_top > 300){
      $('.floating').fadeIn(500);
    }else{
      $('.floating').fadeOut(500);
    }
  })

  $('.floating').click(function(e){
    e.preventDefault();
    $(document.documentElement).animate({
      scrollTop: 0
    },1000);
    //支持chrome
    $(document.body).animate({
      scrollTop: 0
    },1000);
  });

  $('.header_more').each(function(){
    $(this).mouseover(function(){
      $(this).children('.header_more_content').css('display', 'block')
    })
    $(this).mouseout(function(){
      $(this).children('.header_more_content').css('display', 'none')
    })
  })

  $('a[href^="#"]').on("click", function() {
    var h = $(this).attr("href");
    var t = $(h == "#" || h == "" ? 'body' : h);
    var headHeight = $('.header').height();
    var p = t.offset().top

    if (isPC()) {
      p = p - headHeight
    }

    $('html,body').animate({
      scrollTop: p
    }, 500)
    return false;
  });

  var $target = window.location.hash;
    window.setTimeout(function() {
      scrollAnimate($target);
    }, 50)

    function scrollAnimate (el) {
      if(!el) return

      $('html, body').animate({
        scrollTop: $(el).offset().top - 110
      }, 50);
    }
})


